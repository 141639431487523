export const readZipAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = async (e) => {
      const nameTest = /[A-Za-z0-9_+]+-(\d+x\d+).zip/.exec(file.name);

      if (nameTest === null) {
        reject(
          "Ce fichier ZIP n'a pas la taille dans son nom. Exemple: NOM_DU_ZIP-375x812.zip (Largeur X Hauteur)"
        );
        return;
      }

      if (file.size > 2097152) {
        //2Mo
        reject(
          "Votre fichier ZIP est trop lourd. Merci de ne pas dépasser les 2 Mo."
        );
      }

      const dimensions = nameTest[1].split("x");

      const zipMetadata = {
        name: file.name,
        dimensions: {
          width: parseInt(dimensions[0]),
          height: parseInt(dimensions[1]),
        },
        base64: e.target.result,
        size: file.size,
        type: "zip",
      };
      resolve(zipMetadata);
    };
    reader.readAsDataURL(file);
  });
};

const getVideoDuration = (blob) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onerror = reject;

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };

    video.src = URL.createObjectURL(blob);
  });
};

export const readVideoAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = async (e) => {
      const zipMetadata = {
        name: file.name.split(".")[0],
        dimensions: {
          width: null,
          height: null,
        },
        base64: e.target.result,
        size: file.size,
        type: "video",
      };

      const maxBirate = 2097152 / 10; //Bitrate is 2Mo/10 secs

      try {
        const fetchVideo = await fetch(e.target.result);
        const duration = await getVideoDuration(await fetchVideo.blob());

        const currentBirate = file.size / duration;
        if (currentBirate > maxBirate) {
          reject("Votre vidéo est trop lourde (max 2 Mo par 15 secondes).");
        }
      } catch (error) {
        reject("Impossible de lire la vidéo.");
      }
      resolve(zipMetadata);
    };
    reader.readAsDataURL(file);
  });
};

export const readImageAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = async (e) => {
      const img = new Image();
      const dataUrl = e.target.result;

      img.src = dataUrl;

      img.onerror = reject;

      if (file.size > 262144) {
        reject(
          "Votre image est trop lourde. Merci de ne pas dépasser les 200 Ko."
        );
      }

      img.onload = () => {
        const imgMetadata = {
          name: file.name,
          base64: dataUrl,
          dimensions: {
            width: img.width,
            height: img.height,
          },
          size: file.size,
          type: "image",
        };
        resolve(imgMetadata);
      };
    };

    reader.readAsDataURL(file);
  });
};

export const getFileSize = (file) => {
  return (file.size / 1024 ** 1).toFixed(2);
};

export const getFilesSize = (files) => {
  const listOfSize = files.map((file) => {
    return file.size / 1024 ** 1;
  });
  return listOfSize.reduce((acc, cur) => acc + cur, 0).toFixed(2);
};
